<template>
  <div class="info-header-wrapper">
    <div class="left">
      <p class="single-row">维修单号： {{detailData.repairNo}}</p>
      <p class="single-row">设备名称： {{detailData.deviceName}}</p>
      <p class="single-row">设备编号： {{detailData.deviceNo}}</p>
      <p class="single-row">所属部门： {{detailData.deptName}}</p>
    </div>
    <div class="right">
      <div class="icon">
        <!-- <img :src="imgUrl + detailData.devicePhotoUrl" alt=""> -->
        <img :src="detailData.devicePhotoUrl?imgUrl + detailData.devicePhotoUrl:Default" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { imgUrl } from '@/config/env'
import Default from '@/assets/img/nodata/default.png'

export default {
  name: 'InfoHeader',
  props: {
    detailData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      imgUrl,
      Default,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.info-header-wrapper {
  @include wh(100%, 8rem);
  background: linear-gradient(
    90deg,
    rgba(79, 147, 255, 1) 0%,
    rgba(58, 187, 253, 1) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1.2rem;
  .left {
    p {
      width: 10rem;
      font-size: 0.6rem;
      color: white;
      line-height: 1.5rem;
      font-weight: 400;
    }
  }
  .right {
    .icon {
      @include wh(5rem, 5rem);
      background-color: white;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
